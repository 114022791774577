export const CartActionTypes = {
  GET_CART_COUNT_SUCCESS: "GET_CART_COUNT_SUCCESS",
  GET_CART_PENDING: "GET_CART_PENDING",
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  GET_CART_ERROR: "GET_CART_ERROR",

  POST_CHECKOUT_PENDING: "POST_CHECKOUT_PENDING",
  POST_CHECKOUT_SUCCESS: "POST_CHECKOUT_SUCCESS",
  POST_CHECKOUT_ERROR: "POST_CHECKOUT_ERROR",
};
