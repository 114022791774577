export const MerchantsActionTypes = {
  GET: {
    GET_MERCHANT_PENDING: "GET_MERCHANT_PENDING",
    GET_MERCHANT_SUCCESS: "GET_MERCHANT_SUCCESS",
    GET_MERCHANT_ERROR: "GET_MERCHANT_ERROR",

    GET_MERCHANT_BY_ID_PENDING: "GET_MERCHANT_BY_ID_PENDING",
    GET_MERCHANT_BY_ID_SUCCESS: "GET_MERCHANT_BY_ID_SUCCESS",
    GET_MERCHANT_BY_ID_ERROR: "GET_MERCHANT_BY_ID_ERROR",

    GET_MERCHANT_BY_CATEGORY_ID_PENDING: "GET_MERCHANT_BY_CATEGORY_ID_PENDING",
    GET_MERCHANT_BY_CATEGORY_ID_SUCCESS: "GET_MERCHANT_BY_CATEGORY_ID_SUCCESS",
    GET_MERCHANT_BY_CATEGORY_ID_ERROR: "GET_MERCHANT_BY_CATEGORY_ID_ERROR",

    GET_MERCHANT_PRODUCTS_PENDING: "GET_MERCHANT_PRODUCTS_PENDING",
    GET_MERCHANT_PRODUCTS_SUCCESS: "GET_MERCHANT_PRODUCTS_SUCCESS",
    GET_MERCHANT_PRODUCTS_ERROR: "GET_MERCHANT_PRODUCTS_ERROR",
  },
};
