import { Link } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import cx from "classnames";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { IoIosChatbubbles } from "react-icons/io";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { useDispatch, useSelector } from "react-redux";

import GrayIcon from "./GrayIcon";
import { setSidebarState } from "../../redux/actions-exporter";

import chatIcon from "../../assets/images/footer/chat.png";
import "./Footer.css";

const containerArr = [
  {
    img: <HomeIcon />,
    link: "/",
  },
  {
    img: <StoreMallDirectoryIcon />,
    link: "/allvendors",
  },
  {
    img: <ShoppingCartIcon />,
    link: "/cart",
  },
  {
    img: <LocalMallIcon />,
    link: "/malls",
  },
  {
    img: <LocalOfferIcon />,
    link: "/sales",
  },
];

const Footer = () => {
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector((state) => state.sidebar.isOpen);

  let url = window.location.pathname;

  return (
    <>
      <BrowserView>
        <div className="webFooter">
          <div
            className={cx(
              "customizer border-left-blue-grey border-left-lighten-4",
              isOpenSidebar ? "open" : "d-none d-md-block"
            )}
          >
            <div className="customizer-toggle box-shadow-3">
              {containerArr.map((el, i) => {
                return (
                  <Link to={el.link} key={i}>
                    {el.img}
                  </Link>
                );
              })}

              <Link to="#">
                <IoIosChatbubbles
                  onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
                />
              </Link>
            </div>
            <div
              id="widget-3ead65eecdd6fa73d23809784f831a8987e47d1f"
              data-view="slide"
            />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div>
          <footer className="footer">
            <div
              className={cx(
                "customizer border-left-blue-grey border-left-lighten-4",
                isOpenSidebar ? "open" : "d-none d-md-block"
              )}
            >
              <div
                id="widget-3ead65eecdd6fa73d23809784f831a8987e47d1f"
                data-view="slide"
              />
            </div>
            <div className="sosAndGray">
              <div className="grayIcons">
                {containerArr.map((el, i) => {
                  return (
                    <GrayIcon
                      img={el.img}
                      i={i}
                      url={url}
                      key={i}
                      link={el.link}
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="mainChat"
              onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
            >
              <img alt="" className="chat" src={chatIcon} />
            </div>
          </footer>
        </div>
      </MobileView>
    </>
  );
};

export default Footer;
